.navbar_main {
  width: 100%;
  background: #101010;
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar_main > h1 {
  font-family: "primary";
  font-weight: 700;
  color: white;
}

.user_avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user_avatar > h3 {
  font-family: "primary";
  color: white;
  font-weight: 600;
  margin: 0;
  margin-right: 10px;
  font-size: 1.5em;
}

.user_avatar > img {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
